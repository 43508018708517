<template>
  <div style="display: contents">
    <svg class="duoicon duoicon-bank" height="66"
         style="color: #3b8aff;" viewBox="0 0 24 24" width="66" xmlns="http://www.w3.org/2000/svg">
      <path class="duoicon-secondary-layer" d="m12.67 2.217 8.5 4.75A1.5 1.5 0 0 1 22 8.31v1.44c0 .69-.56 1.25-1.25 1.25H20v8h1a1 1 0 1 1 0 2H3a1 1 0 1 1 0-2h1v-8h-.75C2.56 11 2 10.44 2 9.75V8.31c0-.522.27-1.002.706-1.274l8.623-4.819c.422-.211.92-.211 1.342 0h-.001Z"
            fill="currentColor"
            fill-rule="evenodd" opacity=".3"></path>
      <path class="duoicon-primary-layer" d="M12 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm5 5H7v8h2v-6h2v6h2v-6h2v6h2v-8Z"
            fill="currentColor" fill-rule="evenodd"></path>
    </svg>
  </div>
</template>

<script>
export default {
    name: "HomeIcon"
}
</script>

<style scoped>

</style>