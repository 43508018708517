<template>
  <div style="display: contents">
    <svg class="duoicon duoicon-alert-octagon" height="66" style="color: #3b8aff;" viewBox="0 0 24 24" width="66"
         xmlns="http://www.w3.org/2000/svg">
      <path class="duoicon-secondary-layer" d="M15.314 2a2 2 0 0 1 1.414.586l4.686 4.686A2 2 0 0 1 22 8.686v6.628a2 2 0 0 1-.586 1.414l-4.686 4.686a2 2 0 0 1-1.414.586H8.686a2 2 0 0 1-1.414-.586l-4.686-4.686A2 2 0 0 1 2 15.314V8.686a2 2 0 0 1 .586-1.414l4.686-4.686A2 2 0 0 1 8.686 2h6.628Z"
            fill="currentColor"
            fill-rule="evenodd" opacity=".3"></path>
      <path class="duoicon-primary-layer" d="M12 6a1 1 0 0 0-.993.883L11 7v6a1 1 0 0 0 1.993.117L13 13V7a1 1 0 0 0-1-1Zm0 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
            fill="currentColor"
            fill-rule="evenodd"></path>
    </svg>
  </div>
</template>

<script>
export default {
    name: "AlertIcon"
}
</script>

<style scoped>

</style>