<template>
  <div style="display: contents">
    <svg class="duoicon duoicon-shopping-bag"
         height="66"
         style="color: #3b8aff;"
         viewBox="0 0 24 24"
         width="66"
         xmlns="http://www.w3.org/2000/svg">
      <path class="duoicon-secondary-layer"
            d="M10.464 3.282a2 2 0 0 1 2.964-.12l.108.12L17.468 8h2.985a1.49 1.49 0 0 1 1.484 1.655l-.092.766-.1.74-.082.554-.095.595-.108.625-.122.648-.136.661c-.072.333-.149.667-.232.998a20.957 20.957 0 0 1-.832 2.584l-.221.54-.214.488-.202.434-.094.194-.249.49c-.32.61-.924.97-1.563 1.022l-.16.006H6.555a1.928 1.928 0 0 1-1.71-1.008l-.232-.45-.18-.37-.095-.205-.2-.449a21.511 21.511 0 0 1-1.108-3.276 34.602 34.602 0 0 1-.156-.654l-.142-.648-.127-.634-.112-.613-.1-.587-.087-.554-.074-.513-.09-.683-.066-.556-.017-.153a1.489 1.489 0 0 1 1.348-1.64L3.543 8h2.989l3.932-4.718Z"
            fill="currentColor"
            fill-rule="evenodd" opacity=".3"></path>
      <path class="duoicon-primary-layer"
            d="M12 4.562 9.135 8h5.73L12 4.562Zm3.164 7.452a1 1 0 0 0-1.125.708l-.025.114-.5 3a1 1 0 0 0 1.947.442l.025-.114.5-3a1 1 0 0 0-.822-1.15Zm-5.203.708a1 1 0 0 0-1.96.326l.013.116.5 3 .025.114a1 1 0 0 0 1.96-.326l-.013-.116-.5-3-.025-.114Z"
            fill="currentColor"
            fill-rule="evenodd"></path>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>